import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/FirldTrips/2023/Class3/1.jpg';
import p2 from 'assests/Photos/FirldTrips/2023/Class3/2.jpg';
import p3 from 'assests/Photos/FirldTrips/2023/Class3/3.jpg';
import p4 from 'assests/Photos/FirldTrips/2023/Class3/4.jpg';
import p5 from 'assests/Photos/FirldTrips/2023/Class3/5.jpg';
import p6 from 'assests/Photos/FirldTrips/2023/Class3/6.jpg';
import p7 from 'assests/Photos/FirldTrips/2023/Class3/7.jpg';
import p8 from 'assests/Photos/FirldTrips/2023/Class3/8.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar_2023 } from './components';
import Archive_2022 from './Archive_2022';

const CurricularClass32023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
        src: p8,
        source: p8,
        rows: 1,
        cols: 2,
    },
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
        src: p2,
        source:p2,
        rows: 1,
        cols: 2,
    },
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 2,
    },
    {
    src: p7,
    source: p7,
    rows: 1,
    cols: 1,
    }, 
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
    }, 
    {
    src: p6,
    source: p6,
    rows: 1,
    cols: 2,
    },   
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘Field Trip to Model Heritage Village’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 3   Date: 6 SEPTEMBER 2023 (Wednesday)

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        National Public School, Yeshwanthpur had organized a field trip for the students of Class to Model Heritage Village, Jakkur on Wednesday, 6 September 2023. The purpose of this trip was to learn about how communities have evolved and to gain a better understanding of rural environments. It offered a unique opportunity for our young learners to witness the features of our agrarian and rural society.  
        <br />
        The day started with a guided tour of the village. Our guide explained the layout, infrastructure and the various community helpers who make our lives easier. The students were fascinated by the houses in the village which were built in various architectural styles. 
        <br />
        The class 3 field trip was both educational and enjoyable. It gave our students a hands-on experience of a rural community, helping them appreciate the importance of sustainable living, community engagement and the benefits of well-planned infrastructure. 
        <br />
        The trip was a success in achieving its educational objectives and the students returned with a wealth of knowledge about our villages. 
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Adventure is the best way to explore and exploration has no bounds”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
                <Sidebar_2023/>
             </Box>

         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default CurricularClass32023;